<template>
  <div class="layout-config h-100 p-10">
    <h3>{{ $route.meta.title }}</h3>
    <Row :gutter="10" class="m-t-10">
      <Col span="6">
        <Card>
          <p class="m-b-5">
            智能教室ID：
            <Tag
              type="dot"
              :color="classroomState == 'online' ? 'success' : ''"
              v-if="classroomState"
              >{{ classroomState == "online" ? "在线" : "离线" }}</Tag
            >
          </p>
          <Input
            v-model="classroomId"
            placeholder="请输入智能教室ID"
            clearable
          />
          <div class="m-t-5 text-r">
            <Button type="info" @click="listenClient">监听</Button>
          </div>
        </Card>
      </Col>
      <template v-if="isClient">
        <Col span="6">
          <Card>
            <p class="m-b-5">将设备从所有群组中删除：</p>
            <Input v-model="deviceId" placeholder="请输入设备ID" clearable />
            <div class="m-t-5 text-r">
              <!-- <Button type="primary" class="m-r-5" @click="showModal(deviceId)"
                >配置</Button
              > -->
              <Button type="error" @click="delDevice">一键删除</Button>
            </div>
          </Card>
        </Col>

        <Divider orientation="left">设备信息</Divider>
        <Col span="4" v-for="(item, index) in devicesList" :key="'device_' + index">
          <Card class=" m-r-5 m-t-10">
            <!-- TODO:设备的在线离线 未有参数判断-->
            <div class="text-b">{{ item.friendly_name }}</div>
            <div class="m-t-10">ID：{{ item.ieee_address }}</div>
            <div class="">类型：{{ item.type }}</div>
            <div class="text-r">
              <Button size="small" @click="showModal(item.ieee_address)">配置</Button>
              <!-- <Button size="small" @click="showModal(item.ieee_address)" class="m-l-5">添加场景</Button> -->
            </div>
          </Card>
        </Col>
        <Divider orientation="left">群组信息</Divider>
        <Col span="6" v-for="(item, index) in groups" :key="index">
          <Card>
            <div>
              <span class="f-14 text-b m-r-10">{{ item.friendly_name }}</span>
              <Tag
                type="dot"
                :color="
                  groupIds[item.friendly_name] == 'online' ? 'success' : ''
                "
                v-if="groupIds[item.friendly_name]"
                >{{
                  groupIds[item.friendly_name] == "online" ? "在线" : "离线"
                }}</Tag
              >
              <Button
                :icon="
                  isAdd && current == index ? 'ios-arrow-up' : 'ios-arrow-down'
                "
                size="small"
                @click="isOpen(index)"
              />
              <Button
                icon="md-build"
                type="primary"
                size="small"
                class="m-l-5"
                @click="showModal(item.friendly_name)"
              />
            </div>
            <div class="m-t-10 p-t-10 b-t-1" v-if="isAdd && current == index">
              <p>添加设备</p>
              <Input
                v-model="deviceIds[index]"
                placeholder="请输入设备ID"
                clearable
                class="w-70 m-r-5"
              />
              <Button
                icon="md-done-all"
                type="primary"
                class="m-r-5"
                @click="addGroupMember(item, index)"
              />
              <Button icon="md-close" type="warning" @click="isAdd = false" />
            </div>
            <div class="m-t-10 p-t-10 b-t-1">
              <template v-if="item.members.length">
                <Tag
                  type="dot"
                  closable
                  color="success"
                  v-for="(node, key) in item.members"
                  :key="'member_' + key"
                  @on-close="delGroupMember(item, node)"
                  >{{ node.ieee_address }}</Tag
                >
              </template>
              <div class="c-gary" v-else>暂无设备</div>
            </div>
            <div class="m-t-10 p-t-10 b-t-1">
              <template v-if="item.scenes.length">
                <div
                  class="scenes-item m-b-5 m-r-5"
                  v-for="(node, key) in item.scenes"
                  :key="'scene_' + key"
                >
                  <span class="c-h call" @click="callScene(item, node)">{{
                    node.name
                  }}</span>
                  <span
                    class="reset c-h f-12 m-l-5"
                    @click="showModal(item.friendly_name, true, node)"
                    >定义场景</span
                  >
                  <span
                    class="reset c-h f-12 m-l-5"
                    @click="showResetSceneName(item, 'rename', node)"
                    >重命名</span
                  >
                  <Icon
                    type="md-close"
                    :size="14"
                    class="c-h close"
                    @click="delGroupScene(item, node)"
                  />
                </div>
              </template>
              <Button
                icon="md-add"
                type="dashed"
                size="small"
                @click="showResetSceneName(item, 'add')"
                >添加场景</Button
              >
            </div>
          </Card>
        </Col>
      </template>
    </Row>
    <GroupModal :modal="modal" @change="groupSetting" />
    <Modal
      v-model="iScene"
      :title="scene.type == 'add' ? '创建场景' : `场景-${scene.id}重命名`"
      @on-ok="resetSceneName"
    >
      <Form ref="sceneForm" :model="scene" label-colon class="w-90 m-l-20">
        <FormItem label="场景名称">
          <Input v-model="scene.name" placeholder="请输入场景名称" clearable />
        </FormItem>
      </Form>
    </Modal>
    <div class="m-t-10"></div>
  </div>
</template>

<script>
import mqtt from "mqtt";
import { util } from "@/libs";
import GroupModal from "./internetOfThing/modal.vue";
export default {
  components: { GroupModal },
  data() {
    return {
      classroomId: "classroom_1001",
      groups: [],
      isAdd: false,
      deviceId: "",
      sceneId: "",
      iScene: false,
      scene: {},
      deviceIds: {},
      current: 0,
      isClient: false,
      classroomState: null,
      groupIds: {},
      devicesList: [],
      modal: {
        show: false,
        group: "",
      },
    };
  },
  methods: {
    // 群组是否添加设备
    isOpen(index) {
      if (this.current == index) {
        this.isAdd = !this.isAdd;
        this.deviceIds = {}
      } else {
        this.isAdd = true;
        this.current = index;
      }
    },
    // 配置群组
    showModal(group, flag = false, scene = {}) {
      if (group) {
        Object.assign(this.modal, { show: true, group, flag, scene });
      } else {
        this.$Message.warning("暂无群组ID或设备ID，请先确认ID信息！");
      }
    },
    // 配置群组或者设备参数
    groupSetting(res) {
      if (Object.keys(res).length == 0) {
        this.$Message.warning("请先选择配置参数！");
        return;
      }
      // console.log(this.modal, res)
      const { group, flag, scene } = this.modal
      // flag: true, 将状态添加到场景
      if(flag) {
        let params = {
          scene_add: {
            'ID': scene.id,
            'name': scene.name,
            ...res
          }
        }
        this.publish(`zigbee/${this.classroomId}/${group}/set`, params);
      } else {
        // 设置群组或设备的状态
        this.publish(`zigbee/${this.classroomId}/${group}/set`, res);
      }
      Object.assign(this.modal, { show: false })
    },
    // 添加群组设备
    addGroupMember(item, index) {
      if (!this.deviceIds[index]) {
        this.$Message.warning("请先输入设备ID");
        return;
      }
      let deviceId = this.deviceIds[index];
      let params = {
        group: item.friendly_name,
        device: deviceId,
      };
      console.log(this.deviceIds, this.deviceIds[index], deviceId, params)

      // this.publish(
      //   `zigbee/${this.classroomId}/bridge/request/group/members/add`,
      //   params
      // );
    },
    // 删除群组设备
    delGroupMember(parent, child) {
      let params = {
        group: parent.friendly_name,
        device: child.ieee_address,
      };
      this.$Modal.confirm({
        title: "群组设备删除",
        content: `确定删除群组--${parent.friendly_name}的设备--${child.ieee_address}吗？`,
        onOk: () => {
          this.publish(
            `zigbee/${this.classroomId}/bridge/request/group/members/remove`,
            params
          );
        },
      });
    },

    // 将设备从所有群组删除
    delDevice() {
      if (!this.deviceId) {
        this.$Message.warning("请先输入设备ID！");
        return;
      }
      let params = {
        device: this.deviceId,
      };

      this.$Modal.confirm({
        title: "设备删除",
        content: `确定删除设备--${this.deviceId}吗？`,
        onOk: () => {
          this.publish(
            `zigbee/${this.classroomId}/bridge/request/group/members/remove_all`,
            params
          );
        },
      });
    },

    /**
     * 场景相关
     */
    // 打开场景创建、重命名弹框
    showResetSceneName(parent, type, child = {}) {
      this.iScene = true;
      this.scene = util.deepcopy({
        group: parent.friendly_name,
        length: parent.scenes.length,
        type,
        ...child,
      });
    },

    // 添加场景、场景重命名
    resetSceneName() {
      let { scene } = this;
      let params = {};
      if (scene.type == "add") {
        params = {
          scene_store: {
            ID: this.scene.length,
            name: this.scene.name,
          },
        };
      } else {
        params = {
          scene_rename: {
            ID: this.scene.id,
            name: this.scene.name,
          },
        };
      }
      this.publish(
        `zigbee/${this.classroomId}/${this.scene.group}/set`,
        params
      );
    },

    // 召唤场景
    callScene(parent, child) {
      let params = { "scene_recall": child.id }
      this.publish(
        `zigbee/${this.classroomId}/${parent.friendly_name}/set`,
        params
      );
    },

    // 删除群组场景
    delGroupScene(parent, child) {
      this.$Modal.confirm({
        title: "删除场景",
        content: `确定删除群组--${parent.friendly_name}下的场景--${child.name}吗？`,
        onOk: () => {
          let params = {
            scene_remove: child.id,
          };
          this.publish(
            `zigbee/${this.classroomId}/${parent.friendly_name}/set`,
            params
          );
        },
      });
    },

    // 推送公用方法
    publish(topic, params) {
      let paramsStr = JSON.stringify(params);
      this.$data.client.publish(topic, paramsStr, { qos: 1 });
    },

    listenClient() {
      if (!this.classroomId) {
        this.$Message.warning("请先输入智能教室ID！");
        return;
      }
      this.isAdd = false;
      this.createClient();
    },
    createClient() {
      // TODO: 用户密码为测试用
      let userId = localStorage.getItem("userId");
      let options = {
        clientId: util.uuid(), //随机
        username: "demo_0", // 用户
        password: "zzzz1234", // 密码
        clean: true, //true: 清除会话， false: 保留会话
        connectTimeout: 4000, // 超时时间
      };
      const client = mqtt.connect(
        `ws://${
          process.env.NODE_ENV == "production" ? "xingyaedu.cn" : "192.168.1.1"
        }:8083/mqtt`,
        options
      );
      console.log("client", client)
      client.on("connect", () => {
        console.log("MQTT连接成功");
      });
      client.subscribe(
        `${process.env.NODE_ENV == "production" ? "remote/" : ""}zigbee/${
          this.classroomId
        }/#`,
        { qos: 0 }
      ); //添加⼀条消息订阅
      client.on("message", (topic, message) => {
        if (!topic.includes("/log")) {
          console.log(`${topic}: ${message.toString()}`);
        }
        // 获取群组信息
        if (topic.includes("/groups")) {
          this.groups = JSON.parse(message.toString());
        }
        // 教室获取在线状态
        if (topic.includes(`${this.classroomId}/bridge/state`)) {
          this.classroomState = message.toString();
        }
        // 获取群组在线状态
        if (topic.includes("/availability")) {
          let groupId = topic.split("/")[2];
          this.groupIds[groupId] = message.toString();
        }

        if (topic.includes("/bridge/devices")) {
          this.devicesList = JSON.parse(message.toString());
          console.log("this.deviceList", this.devicesList)
        }
      });
      client.on("close", () => {
        this.isClient = false;
      });
      this.$data.client = client;
      this.isClient = true;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.device-item {
  display: inline-block;
  border: solid 1px #eee;
  border-radius: 5px;
  padding: 10px;
  .device-close {
    &:hover {
      color: #2D8CF0;
    }
  }
}
.scenes-item {
  display: inline-block;
  border: solid 1px #eee;
  border-radius: 5px;
  padding: 1px 8px;
  .call {
    color: #333;
    &:hover {
      color: #2D8CF0;
    }
  }
  .reset {
    color: #ccc;
    &:hover {
      color: #5e5ef5;
    }
  }
  .close {
    color: #ccc;
    &:hover {
      color: #f13c3c;
    }
  }
}
</style>

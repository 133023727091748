<template>
  <Modal v-model="modal.show" :title="modal.title || modal.group">
    <Form
      ref="form"
      :model="form"
      label-position="left"
      :label-width="80"
      label-colon
    >
      <RadioGroup
        v-model="type"
        type="button"
        @on-change="getType"
        class="m-t-10 m-b-10"
        v-if="!modal.typeShow"
      >
        <Radio label="1">灯光</Radio>
        <Radio label="2">窗帘</Radio>
        <Radio label="3">开关</Radio>
      </RadioGroup>
      <template v-if="type == 1">
        <FormItem label="控制开关">
          <RadioGroup v-model="form.state">
            <Radio label="ON">开</Radio>
            <Radio label="OFF">关</Radio>
            <Radio label="TOGGLE">切换</Radio>
          </RadioGroup>
          <Button
            type="warning"
            size="small"
            icon="md-close"
            @click="form.state = null"
            v-if="form.state"
          ></Button>
        </FormItem>
        <FormItem label="控制亮度">
          <Slider v-model="form.brightness" :step="1" :min="0" :max="254" />
        </FormItem>
        <FormItem label="颜色模式">
          <RadioGroup v-model="form.colorMode">
            <Radio label="xy">彩光</Radio>
            <Radio label="color_temp">白光</Radio>
          </RadioGroup>
          <Button
            type="warning"
            size="small"
            icon="md-close"
            @click="form.colorMode = null"
            v-if="form.colorMode"
          ></Button>
        </FormItem>
        <FormItem label="控制色温" v-if="form.colorMode == 'color_temp'">
          <Slider v-model="form.colorTemp" :step="1" :min="153" :max="500" />
        </FormItem>
        <FormItem label="控制灯光" v-if="form.colorMode == 'xy'">
          <input v-model="form.color" type="color" />
        </FormItem>
        <FormItem label="灯效">
          <SelectDict v-model="form.effect" dict="effect" class="w-60" />
        </FormItem>
        <FormItem label="过渡">
          <InputNumber v-model="form.transition" />
        </FormItem>
        <FormItem label="渐变">
          <template v-if="form.colorMode == 'xy'">
            亮度：<InputNumber v-model="form.brightness_move" />
            亮度变化：
            <InputNumber v-model="form.brightness_step" />
          </template>
          <template v-else-if="form.colorMode == 'color_temp'">
            色温：<InputNumber
              v-model="form.color_temp_move"
              :min="153"
              :max="500"
            />
            色温变化：
            <InputNumber v-model="form.color_temp_step" />
          </template>
          <span class="f-10" v-else>请先选择颜色模式！</span>
        </FormItem>
        <FormItem label="断电后清除状态">
          <RadioGroup v-model="form.do_not_disturb">
            <Radio label="1">是</Radio>
            <Radio label="0">否</Radio>
          </RadioGroup>
          <Button
            type="warning"
            size="small"
            icon="md-close"
            @click="form.do_not_disturb = null"
            v-if="form.do_not_disturb"
          ></Button>
        </FormItem>
      </template>
      <template v-if="type == 2">
        <FormItem label="开关">
          <RadioGroup v-model="form.state">
            <Radio label="OPEN">打开</Radio>
            <Radio label="CLOSE">关闭</Radio>
            <Radio label="STOP">停止一切动作</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="方向">
          <Row>
            <Col span="5">点击速度：</Col>
            <Col span="19"
              ><Slider
                v-model="form.options.motor_speed"
                :step="1"
                :min="0"
                :max="255"
            /></Col>
          </Row>
          <div></div>
          <div>
            启用反转：
            <RadioGroup v-model="form.options.reverse_direction">
              <Radio label="1">是</Radio>
              <Radio label="0">否</Radio>
            </RadioGroup>
          </div>
        </FormItem>
        <FormItem label="位置">
          <Slider v-model="form.position" :step="1" :min="0" :max="100" />
        </FormItem>
      </template>
      <template v-if="type == 3">
        <FormItem label="开关类型">
          <RadioGroup v-model="switchType" @on-change="getSwitchType">
            <Radio label="1" :disabled="modal.disabled">单键开关</Radio>
            <Radio label="2" :disabled="modal.disabled">三键开关</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="控制开关" v-if="switchType == 1">
          <RadioGroup v-model="form.state">
            <Radio label="ON">开</Radio>
            <Radio label="OFF">关</Radio>
          </RadioGroup>
          <Button
            type="warning"
            size="small"
            icon="md-close"
            @click="form.state = null"
            v-if="form.state"
          ></Button>
        </FormItem>
        <template v-if="switchType == 2">
          <FormItem label="左边开关">
            <RadioGroup v-model="form.state_left">
              <Radio label="ON">开</Radio>
              <Radio label="OFF">关</Radio>
            </RadioGroup>
            <Button
              type="warning"
              size="small"
              icon="md-close"
              @click="form.state_left = null"
              v-if="form.state_left"
            ></Button>
          </FormItem>
          <FormItem label="中间开关">
            <RadioGroup v-model="form.state_center">
              <Radio label="ON">开</Radio>
              <Radio label="OFF">关</Radio>
            </RadioGroup>
            <Button
              type="warning"
              size="small"
              icon="md-close"
              @click="form.state_center = null"
              v-if="form.state_center"
            ></Button>
          </FormItem>
          <FormItem label="右边开关">
            <RadioGroup v-model="form.state_right">
              <Radio label="ON">开</Radio>
              <Radio label="OFF">关</Radio>
            </RadioGroup>
            <Button
              type="warning"
              size="small"
              icon="md-close"
              @click="form.state_right = null"
              v-if="form.state_right"
            ></Button>
          </FormItem>
        </template>
        <FormItem label="通电行为">
          <RadioGroup v-model="form.power_on_behavior">
            <Radio label="off">off</Radio>
            <Radio label="previous">previous</Radio>
            <Radio label="on">on</Radio>
          </RadioGroup>
          <Button
            type="warning"
            size="small"
            icon="md-close"
            @click="form.power_on_behavior = null"
            v-if="form.power_on_behavior"
          ></Button>
        </FormItem>
        <FormItem label="背光模式">
          <RadioGroup v-model="form.backlight_mode">
            <Radio label="low">低</Radio>
            <Radio label="normal">正常</Radio>
            <Radio label="medium">中</Radio>
            <Radio label="high">高</Radio>
          </RadioGroup>
          <Button
            type="warning"
            size="small"
            icon="md-close"
            @click="form.backlight_mode = null"
            v-if="form.backlight_mode"
          ></Button>
        </FormItem>
      </template>
    </Form>
    <div class="text-r" slot="footer">
      <!--  ok-text="添加" cancel-text="重置" @on-cancel="resetForm" @on-ok="saveData" -->
      <Button type="text" class="m-r-5" @click="resetForm">重置</Button>
      <Button type="primary" @click="saveData">配置</Button>
    </div>
  </Modal>
</template>

<script>
import { util } from "@/libs";
export default {
  props: {
    modal: { type: Object, default: () => {
      return {
        title: null,
        type: '1',
        switchType: '1',
        typeShow: false,
        disabled: false,
      }
    } },
  },
  data() {
    return {
      type: "1",
      switchType: "1",
      form: {
        options: {},
      },
    };
  },
  methods: {
    saveData() {
      let params = this.setParams(util.deepcopy(this.form));
      if (params.color) {
        let color = util.set16ToRgb(params.color, true);
        if (color.length)
          params.color = {
            r: color[0],
            g: color[1],
            b: color[2],
          };
      }
      this.$emit("change", params);
    },
    setParams(obj) {
      let params = {};
      if (obj.do_not_disturb !== undefined)
        obj.do_not_disturb = obj.do_not_disturb == 1 ? true : false;
      for (const key in obj) {
        if (obj[key] === 0) {
          delete obj[key];
        }
        if (
          typeof obj[key] === "object" &&
          obj[key] !== null &&
          Array.isArray(obj[key]) === false
        ) {
          this.setParams(obj[key]);
        }
        if (
          (obj[key] === false || obj[key]) &&
          obj[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== "" &&
          JSON.stringify(obj[key]) !== "{}"
        ) {
          params[key] = obj[key];
        }
      }
      return params;
    },
    getType(val) {
      this.resetForm();
      this.type = val;
    },
    // 切换设备类型
    getSwitchType(val) {
      this.resetForm();
      this.switchType = val;
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.form = {
        options: {},
      };
      if (this.type != 3) this.switchType = "1";
    },
  },
  mounted() {
    this.$watch("modal.show", (show) => {
      if (show) {
        this.resetForm();
        this.type = this.modal.type ? this.modal.type : '1';
        this.modal.switchType ? this.switchType = this.modal.switchType : null;
      }
    });
  },
};
</script>

<style></style>
